import { useState } from "react";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";

import {
  Box,
  Card,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
} from "@chakra-ui/react";

const steps = [
  {
    title: "Configuração",
    description:
      "Aqui vamos configurar e validar os dados do aplicativo e no final gerar as builds",
  },
  {
    title: "Submissão do App",
    description: "Aqui vamos finalizar a configuração e submeter na loja",
  },
];

export const Flux_V2 = ({ ...props }) => {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [body, setBody] = useState({
    slug: ""
  });

  const handleChangeStep = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 === steps.length ? 0 : prevActiveStep + 1
    );
  }

  return (
    <Stack spacing={2} justifyContent={"flex-start"} direction={"column"}>
      <Card padding={6}>
        <Stepper index={activeStep}>
          {steps.map((step, index) => (
            <Step
              key={index}
              onClick={() => setActiveStep(index)}
            >
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box padding={2}>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
                {index === 0 && (
                  <a
                    href="https://youtu.be/AxnEJPB8Ikc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Como preencher as informações?
                  </a>
                )}
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Card>
      <Box width={"100%"} marginTop={4}>
        {activeStep === 0 && <Step1 changeStep={handleChangeStep} setState={setBody} />}
        {activeStep === 1 && <Step2 state={body} />}
      </Box>
    </Stack>
  );
};
