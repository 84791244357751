import {
  Box,
  Button,
  IconButton,
  Image,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Labeled } from "../../../components/molecules/Labeled";
import React from "react";
import { useForm } from "react-hook-form";
import { Info } from "@material-ui/icons";
import { submitApp, verifyExist } from "./api";

export const Step2 = ({ state, setState }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      slug: state.slug || "",
    },
  });

  const slug = watch("slug");
  const toast = useToast();

  const handleSubmitApp = async (formData) => {
    try {
      setIsLoading(true);

      if (!formData.appid) {
        throw new Error("App ID é obrigatório");
      }

      try {
        await verifyExist(formData.slug);
        throw new Error("Slug Não existe");
      } catch (error) {}

      try {
        await submitApp({
          slug: formData.slug,
          appid: formData.appid,
        });
      } catch (error) {
        throw new Error("Erro ao sbmeter o app");
      }

      onOpen();

      return;
    } catch (error) {
      toast({
        title: error.message,
        description: "Entre em contato com a equipe de desenvolvimento.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(handleSubmitApp)}>
      <VStack alignItems={"flex-start"} spacing={4}>
        <Labeled label={"Slug do App"}>
          <Input {...register("slug", { required: true })} />
        </Labeled>
        <OrderedList>
          <ListItem>
            Certifique-se que as builds foram completadas{" "}
            <Popover>
              <PopoverTrigger>
                <IconButton
                  variant="outline"
                  colorScheme="blue"
                  ml={2}
                  size={"sm"}
                  aria-label="Ver Informações"
                  icon={<Info />}
                />
              </PopoverTrigger>
              <PopoverContent width="500px">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader alignItems={"center"}>
                  <Button variant={"link"}>
                    <a
                      href={`https://expo.dev/accounts/partiu.vantagens/projects/${slug}/builds`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Link para as builds
                    </a>
                  </Button>
                </PopoverHeader>
                <PopoverBody>
                  <Box boxSize="fit-content">
                    <Image objectFit={"contain"} src="assets/expo-builds.png" />
                  </Box>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </ListItem>
          <ListItem>
            Android
            <OrderedList>
              <ListItem>
                Clique na build mais recente e clique em download um arquivo
                .aab sera baixado
              </ListItem>
              <ListItem>
                Entre em{" "}
                <Button variant={"link"}>
                  <a
                    href="https://play.google.com/console/u/0/developers"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Google Play Developer Console
                  </a>
                </Button>{" "}
                e crie um novo app
              </ListItem>
              <ListItem>
                Configure a loja e adicione uma nova versão e insira o arquivo
                baixado no teste interno
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      variant="outline"
                      colorScheme="blue"
                      ml={2}
                      size={"sm"}
                      aria-label="Ver Informações"
                      icon={<Info />}
                    />
                  </PopoverTrigger>
                  <PopoverContent width="500px">
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader alignItems={"center"}>
                      Teste interno
                    </PopoverHeader>
                    <PopoverBody>
                      <Box width="fit-content">
                        <Image
                          objectFit={"contain"}
                          src="assets/teste-interno.png"
                        />
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </ListItem>
              <ListItem>
                Após adicionar para teste interno, avise a equipe para testar
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem>
            IOS
            <OrderedList>
              <ListItem>
                Crie um novo app na apple store connect e adicione o bundle id
                escolhido anteriormente
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      variant="outline"
                      colorScheme="blue"
                      ml={2}
                      size={"sm"}
                      aria-label="Ver Informações"
                      icon={<Info />}
                    />
                  </PopoverTrigger>
                  <PopoverContent width="500px">
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader alignItems={"center"}>
                      <Button variant={"link"}>
                        <a
                          href="https://appstoreconnect.apple.com/apps"
                          target="_blank"
                          rel="noreferrer"
                        >
                          App Store Connect
                        </a>
                      </Button>
                    </PopoverHeader>
                    <PopoverBody>
                      <Box width="fit-content">
                        <Image objectFit={"contain"} src="assets/new-app.png" />
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </ListItem>
              <ListItem>
                Após a criação localize o App id
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      variant="outline"
                      colorSchemsluge="blue"
                      ml={2}
                      size={"sm"}
                      aria-label="Ver Informações"
                      icon={<Info />}
                    />
                  </PopoverTrigger>
                  <PopoverContent width="500px">
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader alignItems={"center"}>App ID</PopoverHeader>
                    <PopoverBody>
                      <Box width="fit-content">
                        <Image objectFit={"contain"} src="assets/appid.png" />
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </ListItem>
              <ListItem>
                <Labeled label={"App ID"}>
                  <Input {...register("appid", { required: true })} />
                </Labeled>
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>
        <Button isLoading={isLoading} type="submit">
          Salvar
        </Button>
      </VStack>
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Build no IOS enviada para loja com sucesso!</ModalHeader>
          <ModalBody>
            A build foi enviada para a loja com sucesso. Aguarde de 5 a 10
            minutos para que a build seja processada e enviada para TestFlight.
            Após a conclusão, você poderá prosseguir para a segunda etapa.
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
              }}
              variant="ghost"
            >
              Continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
};
