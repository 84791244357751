import {
  Input,
  VStack,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Labeled } from "../../../components/molecules/Labeled";
import { InputImage } from "../../../components/molecules/InputImage";
import { slugfy } from "../../../utils";
import React from "react";
import { buildApp, verifyExist } from "./api";

export const Step1 = ({ changeStep, setState }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const name = watch("name");
  const slug = watch("slug");
  const toast = useToast();

  React.useEffect(() => {
    setValue("slug", slugfy(slug));
    setValue("bundleIdentifier", `br.com.partiu.${slugfy(slug)}`);
  }, [slug]);

  const handleSubmitApp = async (formData) => {
    try {
      setState(formData);


      setIsLoading(true);

      if (!formData.icon) {
        throw new Error("Icone do App é obrigatório");
      }

      if (!formData.splash) {
        throw new Error("Splash Screen do App é obrigatório");
      }

      formData.bundleIdentifier = `br.com.partiu.${slugfy(formData.slug)}`;

      if (!formData.appBaseUrl.includes("http")) {
        throw new Error("Url do App inválida");
      }

      try {
        await verifyExist(formData.slug);
      } catch (error) {
        throw new Error("Slug já existe");
      }

      try {
        formData.androidPurchaseToken =
          formData.androidPurchaseToken || "vazio";
        await buildApp(formData);
      } catch (error) {
        throw new Error("Erro ao criar o app");
      }

      onOpen();

      return;

    } catch (error) {
      toast({
        title: error.message,
        description: "Entre em contato com a equipe de desenvolvimento.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form style={{ width: "100%" }} onSubmit={handleSubmit(handleSubmitApp)}>
        <VStack spacing={4}>
          <Labeled label={"Nome do App"}>
            <Input {...register("name", { required: true })} />
          </Labeled>
          <Labeled label={"Slug (Nome Sem espaços)"}>
            <Input {...register("slug", { required: true })} />
          </Labeled>
          <Labeled label={"Identificador Unico (br.com.partiu.*)"}>
            <Input
              {...register("bundleIdentifier", {
                disabled: true,
                required: true,
              })}
            />
          </Labeled>
          <Labeled label={"Url do App"}>
            <Input {...register("appBaseUrl", { required: true })} />
          </Labeled>
          <Labeled label={"Icone do App (1024x1024)"}>
            <InputImage
              onUpload={(url) => setValue("icon", url)}
              imageName={`${slugfy(name)}-icon`}
            />
          </Labeled>
          <Labeled label={"Splash Screen do App (1080x1920)"}>
            <InputImage
              onUpload={(url) => setValue("splash", url)}
              imageName={`${slugfy(name)}-splash`}
            />
          </Labeled>
          <Labeled label={"Ios Revenue Cat Token"}>
            <Input {...register("iosPurchaseToken", { required: true })} />
          </Labeled>
          <Labeled label={"Android Revenue Cat Token"}>
            <Input {...register("androidPurchaseToken", { required: false })} />
          </Labeled>
          <Button isLoading={isLoading} type="submit">
            Salvar
          </Button>
        </VStack>
      </form>
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>App Criado com Sucesso!</ModalHeader>
          <ModalBody>
            O aplicativo foi criado com sucesso. Aguarde a conclusão das builds
            para Android e iOS, o que pode levar de 5 a 10 minutos. Você pode
            acompanhar o progresso em{" "}
            <a
              href="https://expo.dev/accounts/partiu.vantagens"
              target="_blank"
              rel="noreferrer"
              s
              style={{ color: "blue" }}
            >
              Expo Dev
            </a>
            . Após a conclusão, você poderá prosseguir para a segunda etapa, que
            é submeter as builds geradas nas lojas.
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                changeStep();
              }}
              variant="ghost"
            >
              Continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
