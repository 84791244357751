import React, { useState } from "react";
import {Spinner, Box, Text} from "@chakra-ui/react";
import RichTextInput from "ra-input-rich-text";
import {
    TextInput,
    ImageInput,
    FileInput,
    ImageField,
    FileField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    BooleanInput,
    TabbedForm,
    FormTab,
    SelectInput,
    AutocompleteInput,
    ReferenceInput,
    useNotify,
    useRefresh,
    useRedirect,
    useDataProvider,
} from "react-admin";

export const BookForm = (props) => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [error, setError] = useState(null);

    const handleSubmit = (data, redirectTo, { onSuccess, onFailure }) => {
        setError(null)
        setLoading(true);
        let response;
        if (data && data.id) {
            response = dataProvider.update('book', { id: data.id, data });
        } else {
            response = dataProvider.create('book', { data });
        }

        response
            .then(res => {
                if (res.status === 400) {
                    throw new Error(res.data);
                }
                if (res.status < 200 || res.status >= 300) {
                    throw new Error('Erro ao salvar livro/revista');
                }
                setLoading(false);
                onSuccess && onSuccess(res);
            })
            .catch(error => {
                setLoading(false);
                onFailure && onFailure(error);
            });
    };

    const onSuccess = () => {
        notify('Livro/Revista salvo com sucesso!');
        redirect('/book');
        refresh();
    };

    const onFailure = (error) => {
        if (error.response.status === 400) {
            setError(error.response.data)
            notify(`Erro ao salvar livro/revista. Revise o formulário`, 'error');
        } else {
            notify(`Erro ao salvar livro/revista. Status: ${error.response.status}`, 'error');
        }
    };

    const getHelperText = (field) => {
        return <Box marginT="-15px" color={"red.500"}>{error && error[field] ? error[field].join(', ') : null}</Box>
    }

    return (
        <div>
            <TabbedForm
                {...props}
                save={(data, redirectTo) =>
                    handleSubmit(data, redirectTo, { onSuccess, onFailure })
                }
            >
                <FormTab label={"Informações"}>
                    <SelectInput source="type" label="Tipo de Mídia" choices={[{ id: "book", name: "Livro" }, { id: "magazine", name: "Revista" },{ id: "audiobook", name: "Audiobook" }]} />
                    {getHelperText('type')}
                    <SelectInput defaultValue={0} source="level" label={"Nível"} choices={[
                        {id: "0", name: "Gratuito"},
                        {id: "1", name: "Bronze"},
                        {id: "2", name: "Prata"},
                        {id: "3", name: "Ouro"},
                        {id: "4", name: "Diamante"},
                        {id: "5", name: "Light"}
                    ]} />
                    {getHelperText('level')}
                    <ReferenceInput label="Setor" reference="book_sector" source="sector" >
                        <AutocompleteInput source="name" />
                    </ReferenceInput>
                    {getHelperText('sector')}
                    <TextInput required={true} source="title" label="Título" />
                    {getHelperText('title')}
                    <TextInput required={true} source="subtitle" label="Subtítulo" />
                    {getHelperText('subtitle')}
                    <TextInput required={true} source="isbn" label="ISBN" />
                    {getHelperText('isbn')}
                    <TextInput required={true} source="edition" label="Edição" />
                    {getHelperText('edition')}
                    <TextInput required={true} source="publishing_city" label="Cidade de publicação" />
                    {getHelperText('publishing_city')}
                    <TextInput required={true} source="publishing_country" label="País de publicação (2 caracteres)" />
                    {getHelperText('publishing_country')}
                    <ReferenceArrayInput label="Categorias" reference="book_category" source="categories">
                        <AutocompleteArrayInput source="name" />
                    </ReferenceArrayInput>
                    {getHelperText('categories')}
                    <ReferenceInput label="Exclusivo do Convênio" reference="agreement" source="agreements">
                        <AutocompleteArrayInput source="name" />
                    </ReferenceInput>
                    {getHelperText('agreements')}
                    <ReferenceArrayInput label="Autores" reference="book_contributor" source="contributors">
                        <AutocompleteArrayInput source="name" />
                    </ReferenceArrayInput>
                    {getHelperText('contributors')}
                    <ImageField source="cover" label="" />
                    {getHelperText('cover')}
                    <ImageInput source="cover" label="Capa">
                        <ImageField source="cover" />
                    </ImageInput>
                    <FileField source="file" label="" />
                    <FileInput required={true} source="file" label="Arquivo do Livro/Revista">
                        <FileField source="src" title="title" />
                    </FileInput>
                    {getHelperText('file')}
                    <FileInput required={false} source="audiobooks" label="Arquivos do AudioBook" multiple={true}>
                        <FileField source="audiobooks" title="title" />
                    </FileInput>
                    <RichTextInput required={true} multiline source="description" label="Descrição" />
                    {getHelperText('description')}
                    <RichTextInput required={false} multiline source="author_biography" label="Biografia do autor" />
                    {getHelperText('author_biography')}
                    <BooleanInput label={"Mais Lido"} source={"most_read"} />
                    {getHelperText('most_read')}
                    <BooleanInput label={"É para troca?"} source={"is_for_exchange"} />
                    {getHelperText('is_for_exchange')}
                    <BooleanInput label={"Ativo"} source={"active"} />
                    {getHelperText('active')}
                </FormTab>
                <FormTab label={"Livro do mês"}>
                    <SelectInput source="month_featured" label={"Mês"} choices={[
                        { id: null, name: "" },
                        { id: 0, name: "Janeiro" },
                        { id: 1, name: "Fevereiro" },
                        { id: 2, name: "Março" },
                        { id: 3, name: "Abril" },
                        { id: 4, name: "Maio" },
                        { id: 5, name: "Junho" },
                        { id: 6, name: "Julho" },
                        { id: 7, name: "Agosto" },
                        { id: 8, name: "Setembro" },
                        { id: 9, name: "Outubro" },
                        { id: 10, name: "Novembro" },
                        { id: 11, name: "Dezembro" },
                    ]} />
                    <SelectInput source="month_featured_year" label={"Ano"} defaultValue="2023" choices={[
                        { id: 2023, name: "2023" },
                        { id: 2024, name: "2024" },
                    ]} />
                </FormTab>
            </TabbedForm>
            {loading && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="rgba(255, 255, 255, 0.8)"
                    zIndex="9999"
                >
                    <Spinner size="xl" />
                    <Text mt={4}>Modificando/Criando livro... Aguarde alguns instantes</Text>
                </Box>
            )}
        </div>
    );
};
