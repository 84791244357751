import axios from "axios";
import { slugfy } from "../../../utils";

export const verifyExist = (slugForm) => {
  return new Promise((resolve, reject) => {
    let slug = slugfy(slugForm);
    axios
      .get(
        `https://api.github.com/repos/Partiu-Vantagens/whitelabel-base/branches/${slug}`,
        {
          headers: {
            Accept: "application/vnd.github+json",
            Authorization:
              "Bearer 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",
            "X-GitHub-Api-Version": "2022-11-28",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          reject();
        } else {
          resolve();
        }
      })
      .catch((err) => {
        resolve(err);
      });
  });
};

export const buildApp = ({
  name,
  slug,
  appBaseUrl,
  icon,
  splash,
  bundleIdentifier,
  iosPurchaseToken,
  androidPurchaseToken,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://api.github.com/repos/Partiu-Vantagens/whitelabel-base/actions/workflows/create-new-app-v2.yml/dispatches",
        {
          ref: "upgrade-packages",
          inputs: {
            name,
            slug,
            bundleIdentifier,
            appBaseUrl,
            icon,
            splash,
            iosPurchaseToken,
            androidPurchaseToken,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.github+json",
            Authorization:
              "Bearer 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",
            "X-GitHub-Api-Version": "2022-11-28",
          },
        }
      )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const submitApp = ({
  slug,
  appid,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "https://api.github.com/repos/Partiu-Vantagens/whitelabel-base/actions/workflows/submit-build-v2.yml/dispatches",
        {
          ref: "upgrade-packages",
          inputs: {
            slug,
            appid
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/vnd.github+json",
            Authorization:
              "Bearer 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",
            "X-GitHub-Api-Version": "2022-11-28",
          },
        }
      )
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};